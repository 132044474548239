/* main */
.container-analytics {
  display: flex;
  justify-content: space-around;
}
.container-analytics .card-analytics {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 28%;
  border-radius: 4px;
}
.cardSolicitation {
  background: linear-gradient(to top, #54caf2, rgba(47, 129, 237, 0.9));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid #2f81ed;
}
.cardUser {
  background: linear-gradient(to top, #f857a6, rgba(255, 88, 88, 0.9));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid #ff5858;
}
.cardAddress {
  background: linear-gradient(to top, #f2994a, rgba(242, 200, 76, 0.9));
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid #f2994a;
}
.container-analytics .card-analytics i {
  margin: 10px 0;
  padding: 20px;
  font-size: 28px;
  color: #fff;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.3);
}
.container-analytics .card-analytics strong {
  color: #fff;
  font-size: 36px;
}
.container-analytics .card-analytics span {
  font-size: 16px;
  margin-bottom: 20px;
  color: #fff;
  opacity: 0.8;
}
/* Table de Usuários */
.container-table-user {
  margin-top: 30px;
  background: #fff;
  padding: 10px 0;
  border-radius: 4px;
}
.container-table-user .title-table {
  border-bottom: 1px solid #e0e0e0;
  height: 50px;
  margin-top: -10px;
  padding: 15px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.container-table-user h3 {
  color: #444444;
  text-align: left;
  font-size: 17px;
  font-weight: lighter;
}
.container-table-user .content-table {
  overflow-y: scroll;
  max-height: 250px;
}
.container-table-user .content-table table {
  width: 100%;
  margin-top: 30px;
}
.container-table-user .content-table table tbody tr:hover {
  border: 5px;
  background: rgba(47, 129, 237, 0.1);
}
.container-table-user .content-table table thead tr {
  color: #000 !important;
}
.container-table-user .content-table table tr,
td {
  font-size: 14px;
  text-align: center;
  font-weight: 500;
}

.container-table-user .content-table table tr .ico {
  border-radius: 100px;
  background: linear-gradient(to right, #eb3349, #f45c43);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 0 auto;
}
.container-table-user .content-table table tr svg {
  color: #fff;
  width: 40px;
  box-sizing: border-box;
}
.container-table-user .content-table table tr .action {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.container-table-user .content-table table tr button {
  padding: 10px 15px;
  border: none;
  transition: 0.4s;
}
.container-table-user .content-table table tr button i {
  transform: scale(1);
  transition: 0.4s;
}
.container-table-user .content-table table tr button:hover {
  cursor: pointer;
}
.container-table-user .content-table table tr .delete:hover {
  box-shadow: 4px 4px 10px rgba(217, 37, 79, 1);
}
.container-table-user .content-table table tr button:hover i {
  transform: scale(1.2);
}
.container-table-user .content-table table tr .edite:hover {
  box-shadow: -4px -4px 10px rgba(247, 184, 36, 1);
}
.container-table-user .content-table table tr .edite {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
}
.container-table-user .content-table table tr .delete {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  color: #fff;
}
.container-table-user .content-table table tr .cell span i {
  margin-right: 5px;
}
.container-table-user .content-table table tr .cell span {
  background: #3ac47d;
  color: #fff;
  padding: 5px 10px;
  border-radius: 30px;
  font-weight: bold;
}

/* Cards do Usuarios / endereços */
.contentCards {
  display: flex;
  flex-wrap: wrap;
}
/* Sow Solicitations */
.solicitations1 {
  height: 600px !important;
  overflow-y: inherit;
}
.solicitations {
  padding-right: 5px;
  height: 500px !important;
  overflow-y: scroll;
}
.form-new {
  margin-top: 50px;
  border-radius: 10px;
  border: 2px dashed rgba(0, 0, 0, 0.2);
  padding: 15px;
}
#menu1,
#menu2,
#new {
  padding-top: 50px;
}
.infoSolicitations {
  height: 50% !important;
}
.datesSolicitations {
  height: 600px;
}
.solicitations .listSo {
  list-style: none;
}
.solicitations .solicitationInfo {
  padding: 10px;
  position: relative;
  width: 100%;
  margin-top: 10px;
  border-radius: 4px;
  color: #000;
  background: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
.bg-new {
  border-left: 3px #ff3131 solid;
  box-shadow: 2px 2px 10px rgba(253, 53, 80, 0.2) !important;
}
.bg-enviado {
  box-shadow: 2px 2px 10px rgba(255, 165, 0, 0.2) !important;
  border-left: 3px #ffa500 solid;
}
.bg-ok {
  box-shadow: 2px 2px 10px rgba(40, 167, 69, 0.2) !important;
  border-left: 3px #28a745 solid;
}

.lini-info {
  display: flex;
  flex-direction: row;
}
.solicitationInfo .lini-info h5 {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
}
.solicitationInfo .lini-info i {
  padding: 5px 7px;
  color: #0ba360;
  background: #fff;
  border-radius: 50%;
  margin-left: 5px;
}
.solicitationInfo .lini-info .id {
  right: 10px;
  position: absolute;
  font-size: 16px;
  font-weight: normal;
}
.solicitationInfo .type {
  font-size: 16px;
  margin-left: 5px;
}
.solicitationInfo .pay {
  margin-left: 10px;
  font-size: 16px;
}
.solicitationInfo .btnMostrar {
  display: inline-block;
  width: 110%;
  position: relative;
  border-radius: 4;
  background-color: #ececec;
  height: 20;
  left: -16px;
  bottom: -11px;
}
.bg-new .btnMostrar {
  color: #007bff;
  font-weight: bold;
  background: #aed6f1;
}
.bg-enviado .btnMostrar {
  color: #ffa500;
  font-weight: bold;
  background: #fff6c4;
}
.bg-ok .btnMostrar {
  color: #28a745;
  font-weight: bold;
  background: #b8ffc9;
}
.bg-cancel .btnMostrar {
  color: #ff3131;
  font-weight: bold;
  background: #FDEDEC;
}
.btnMostrar:hover {
  opacity: 0.7;
}
.card-body {
  padding: 0 !important;
}
.nav-link1 {
  position: relative;
  padding: 8px 16px !important;
}
.container1 {
  padding: 0px !important;
}

.badge1 {
  position: absolute !important;
  top: 1px;
  right: 1px;
  box-shadow: 0 7px 30px rgba(186, 193, 193, 0.83) !important;
}
.pin {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 2px 5px;
  border-radius: 100px;
  background-color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
}

.div-cardDeliveryman {
  position: relative;
  max-width: 600px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}
.form-deliveryman {
  display: flex;
  flex-direction: column;
}
#name,
#telefone {
  padding: 5px;
  border-radius: 4px;
}
::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  background: #f4f4f4;
}
::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 100px;
  height: 6px;
}


.cardEntregador {
  margin-bottom: 25px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.4);
}