/*
Template Name: BangoDash Admin
Author: KVTHEMES72
Email: kvthemes@gmail.com
File: app-style
*/

/*
  - Google Font
  - General
  - Menu Sidebar Wrapper
  - Page Content Wrapper
  - Topbar Header
  - Dropdown Menu
  - User Details
  - Logo
  - SearachBar
  - Cards
  - Modals
  - Buttons
  - User Cards
  - Widgets
  - Forms
  - Tables
  - Alerts
  - Badges
  - Paginations
  - List Groups
  - Nav Tabs & Pills
  - Accordions
  - Background Colors
  - Borders
  - Text colors
  - Shadows
  - CheckBoxes & Radios
  - Pricing Tables
  - Responsive
*/

/* Google Font*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

/* General */
html {
  font-family: "Open Sans", sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}
@-ms-viewport {
  width: device-width;
}
body {
  background: #f1f1f1;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #636363;
}
[tabindex="-1"]:focus {
  outline: 0 !important;
}

::placeholder {
  color: #747f8a !important;
  font-size: 13px;
  opacity: 0.5 !important; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #747f8a !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #747f8a !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #292929;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  color: #223035;
}
.h1,
h1 {
  font-size: 48px;
  line-height: 52px;
}
.h2,
h2 {
  font-size: 38px;
  line-height: 42px;
}
.h3,
h3 {
  font-size: 30px;
  line-height: 34px;
}
.h4,
h4 {
  font-size: 24px;
  line-height: 28px;
}
.h5,
h5 {
  font-size: 18px;
  line-height: 22px;
}
.h6,
h6 {
  font-size: 14px;
  line-height: 18px;
}

.display-1 {
  font-size: 6rem;
}
.display-2 {
  font-size: 5.5rem;
}
.display-3 {
  font-size: 4.5rem;
}
.display-4 {
  font-size: 3.5rem;
}
.line-height-0 {
  line-height: 0;
}
.line-height-5 {
  line-height: 5px;
}

.line-height-10 {
  line-height: 5px;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
p {
  margin-bottom: 0.65rem;
}
:focus {
  outline: 0 !important;
}
a {
  color: #008cff;
}
a {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

a.text-muted:focus,
a.text-muted:hover {
  color: #748690;
}
hr {
  border-top: 1px solid #e4eaec;
}

/* Menu Sidebar Wrapper */
#wrapper {
  width: 100%;
  position: relative;
}

#sidebar-wrapper {
  background: #1a262b;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  overflow: hidden;
  width: 240px;
  height: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  box-shadow: 2px 10px 30px 0 rgba(0, 0, 0, 0.11),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

/* #wrapper.toggled #sidebar-wrapper {
  position: fixed;
  left: -240px;
} */

/* #wrapper.toggled .menu-icon {
  margin-left: 0px;
} */

/* #wrapper.toggled .content-wrapper {
  margin-left: 0;
} */

/* Page Content Wrapper */
.content-wrapper {
  margin-left: 240px;
  padding-top: 70px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 70px;
  overflow-x: hidden;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/* Topbar Header */
.topbar-nav .navbar {
  padding: 0px 15px;
  z-index: 999;
  height: 60px;
  -webkit-box-shadow: 0 2px 34px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 34px 2px rgba(0, 0, 0, 0.15);
}

.toggle-menu i {
  margin-left: 240px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.right-nav-link a.nav-link {
  padding-right: 0.8rem !important;
  padding-left: 0.8rem !important;
  font-size: 20px;
  color: #fff;
}

/* Dropdown Menu */
.dropdown-menu {
  border: 0px solid rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 8px 16px 0 rgba(40, 40, 90, 0.09),
    0 3px 6px 0 rgba(0, 0, 0, 0.065) !important;
  box-shadow: 0 8px 16px 0 rgba(40, 40, 90, 0.09),
    0 3px 6px 0 rgba(0, 0, 0, 0.065) !important;
  font-size: 15px;
}

.dropdown-menu ul {
  margin-top: 0px;
}

.dropdown-divider {
  margin: 0;
}

.dropdown-item {
  padding: 0.7rem 1.5rem;
}

.dropdown-toggle-nocaret:after {
  display: none;
}

.dropdown-lg .dropdown-menu {
  width: 320px;
  padding: 0;
}

.dropdown-lg
  .dropdown-menu
  .list-group-flush:first-child
  .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 0.25em;
  border-top-right-radius: 0.25em;
}

.dropdown-lg
  .dropdown-menu
  .list-group-flush:last-child
  .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-left-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
}

.dropdown-lg .dropdown-menu .media .avatar img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}
.dropdown-lg .dropdown-menu .media .media-body .msg-title {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0px;
}
.dropdown-lg .dropdown-menu .media .media-body .msg-info {
  font-size: 13px;
  color: #232323;
  margin-bottom: 0;
  white-space: nowrap;
}

/* User Details */
.user-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56),
    0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.user-details .media .avatar img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-details .media .media-body .user-title {
  font-size: 14px;
  color: #000;
  font-weight: 600;
  margin-bottom: 2px;
}

.user-details .media .media-body .user-subtitle {
  font-size: 13px;
  color: #232323;
  margin-bottom: 0;
}

/* Logo */
.brand-logo {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #090e10;
  box-shadow: inset 0 -1px 0px #273e48;
}

.logo-text {
  color: #fff;
  font-size: 20px;
  display: inline-block;
  position: relative;
  top: 3px;
  letter-spacing: 2px;
  font-weight: 400;
  text-align: center;
  line-height: 50px;
}

.logo-icon {
  margin-left: 20px;
  width: 40px;
}

/* SearachBar */
.search-bar {
  margin-left: 20px;
  position: relative;
}

.search-bar input {
  border: 0px solid #ced4da;
  font-size: 15px;
  width: 330px;
  border-radius: 30px;
  height: 30px;
  padding: 0.375rem 2rem 0.375rem 0.75rem;
}

.search-bar a i {
  position: absolute;
  top: 9px;
  right: 12px;
  color: #000;
}
.product-img {
  height: 32px;
}
.page-title {
  font-size: 20px;
  line-height: 20px;
}

.breadcrumb {
  padding: 0;
  background-color: transparent;
}
.sidebar-menu > li > a > i {
  margin-right: 10px;
  font-size: 16px;
}

.lightbox-thumb {
  margin-bottom: 15px;
  width: 100%;
}

.mail-img {
  width: 50px;
}
.row.row-group > div {
  border-right: 1px solid rgba(234, 234, 234, 0.9);
}

.row.row-group > div:last-child {
  border-right: none;
}

/*Cards */
.card {
  margin-bottom: 30px;
  box-shadow: 0 8px 16px 0 rgba(40, 40, 90, 0.09),
    0 3px 6px 0 rgba(0, 0, 0, 0.065);
  border: none;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgb(255, 255, 255) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  font-weight: 600;
  font-size: 14px;
  color: #1a262b;
}

.card-title {
  margin-bottom: 0.75rem;
  font-weight: 600;
  font-size: 16px;
  color: #1a262b;
}
.card-action {
  float: right;
}
.card-action a i {
  color: #1a262b;
}
.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.card-deck {
  margin-bottom: 30px;
}
.card-group {
  box-shadow: 0 8px 16px 0 rgba(40, 40, 90, 0.09),
    0 3px 6px 0 rgba(0, 0, 0, 0.065);
  margin-bottom: 30px;
}
.card-group .card {
  box-shadow: none;
}

.card .card-primary {
  border-bottom: 3px solid #008cff !important;
}
.card .card-success {
  border-bottom: 3px solid #15ca20 !important;
}

.card.card-danger {
  border-bottom: 3px solid #fd3550 !important;
}
.card .card-warning {
  border-bottom: 3px solid #ff9700 !important;
}
.card .card-info {
  border-bottom: 3px solid #0dceec !important;
}
.card .card-light {
  border-bottom: 3px solid #e9eaea !important;
}
.card .card-secondary {
  border-bottom: 3px solid #75808a !important;
}
.card .card-dark {
  border-bottom: 3px solid #223035 !important;
}

.overlay-primary {
  background: #008cff;
  background: rgba(0, 140, 255, 0.81);
}

.overlay-success {
  background: #15ca20;
  background: rgba(21, 202, 32, 0.81);
}

.overlay-danger {
  background: #fd3550;
  background: rgba(253, 53, 80, 0.81);
}

.overlay-warning {
  background: #ff9700;
  background: rgba(255, 151, 0, 0.81);
}

.overlay-info {
  background: #0dceec;
  background: rgba(13, 206, 236, 0.81);
}

.overlay-dark {
  background: #223035;
  background: rgba(34, 48, 53, 0.81);
}

/* Modals */
.modal-header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.modal-content {
  border-radius: 0.25rem;
}

.swal-modal {
  border-radius: 0.25rem;
}
.swal-button,
.swal-button:focus {
  background-color: #008cff;
  box-shadow: 0 7px 30px rgba(2, 140, 253, 0.7) !important;
}

.swal-button--cancel {
  color: #fff;
  background-color: #008cff;
}

.swal-text {
  text-align: center;
}
.swal-footer {
  text-align: center;
}

/*Buttons */
.btn {
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px 20px;
  border-radius: 0.25rem;
  text-transform: uppercase;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.btn:focus {
  box-shadow: none;
}
.btn-lg {
  padding: 15px 41px;
  font-size: 0.95rem;
}

.btn-sm {
  font-size: 10px;
  font-weight: 600;
  padding: 8px 17px;
}

.btn-primary {
  color: #fff;
  background-color: #008cff;
  border-color: #008cff;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary.focus,
.btn-primary:focus {
  box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary {
  color: #fff;
  background-color: #75808a;
  border-color: #75808a;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}
.btn-secondary.focus,
.btn-secondary:focus {
  box-shadow: none;
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success {
  color: #fff;
  background-color: #15ca20;
  border-color: #15ca20;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success.focus,
.btn-success:focus {
  box-shadow: none;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-info {
  color: #fff;
  background-color: #0dceec;
  border-color: #0dceec;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info.focus,
.btn-info:focus {
  box-shadow: none;
}
.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-warning {
  color: #fff;
  background-color: #ff9700;
  border-color: #ff9700;
}
.btn-warning:hover {
  color: #fff;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning.focus,
.btn-warning:focus {
  box-shadow: none;
}
.btn-warning.disabled,
.btn-warning:disabled {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-danger {
  color: #fff;
  background-color: #fd3550;
  border-color: #fd3550;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger.focus,
.btn-danger:focus {
  box-shadow: none;
}
.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-light {
  color: #212529;
  background-color: #e9eaea;
  border-color: #e9eaea;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light.focus,
.btn-light:focus {
  box-shadow: none;
}
.btn-light.disabled,
.btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-dark {
  color: #fff;
  background-color: #223035;
  border-color: #223035;
}
.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}
.btn-dark.focus,
.btn-dark:focus {
  box-shadow: none;
}
.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}
.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}
.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-primary {
  color: #008cff;
  background-color: transparent;
  background-image: none;
  border-color: #008cff;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #008cff;
  border-color: #008cff;
}
.btn-outline-primary.focus,
.btn-outline-primary:focus {
  color: #fff;
  background-color: #008cff;
  border-color: #008cff;
  box-shadow: none;
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #008cff;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #008cff;
  border-color: #008cff;
}
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-secondary {
  color: #75808a;
  background-color: transparent;
  background-image: none;
  border-color: #75808a;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #75808a;
  border-color: #75808a;
}
.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
  color: #fff;
  background-color: #75808a;
  border-color: #75808a;
  box-shadow: none;
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #75808a;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #75808a;
  border-color: #75808a;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success {
  color: #15ca20;
  background-color: transparent;
  background-image: none;
  border-color: #15ca20;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #15ca20;
  border-color: #15ca20;
}
.btn-outline-success.focus,
.btn-outline-success:focus {
  color: #fff;
  background-color: #15ca20;
  border-color: #15ca20;
  box-shadow: none;
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #15ca20;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #15ca20;
  border-color: #15ca20;
}
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #0dceec;
  background-color: transparent;
  background-image: none;
  border-color: #0dceec;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #0dceec;
  border-color: #0dceec;
}
.btn-outline-info.focus,
.btn-outline-info:focus {
  color: #fff;
  background-color: #0dceec;
  border-color: #0dceec;
  box-shadow: none;
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #0dceec;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0dceec;
  border-color: #0dceec;
}
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ff9700;
  background-color: transparent;
  background-image: none;
  border-color: #ff9700;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #ff9700;
  border-color: #ff9700;
}
.btn-outline-warning.focus,
.btn-outline-warning:focus {
  color: #fff;
  background-color: #ff9700;
  border-color: #ff9700;
  box-shadow: none;
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ff9700;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ff9700;
  border-color: #ff9700;
}
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #fd3550;
  background-color: transparent;
  background-image: none;
  border-color: #fd3550;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #fd3550;
  border-color: #fd3550;
}
.btn-outline-danger.focus,
.btn-outline-danger:focus {
  color: #fff;
  background-color: #fd3550;
  border-color: #fd3550;
  box-shadow: none;
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #fd3550;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #fd3550;
  border-color: #fd3550;
}
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: #e9eaea;
  background-color: transparent;
  background-image: none;
  border-color: #e9eaea;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #e9eaea;
  border-color: #e9eaea;
}
.btn-outline-light.focus,
.btn-outline-light:focus {
  color: #212529;
  background-color: #e9eaea;
  border-color: #e9eaea;
  box-shadow: none;
}
.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #e9eaea;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #e9eaea;
  border-color: #e9eaea;
}
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-dark {
  color: #223035;
  background-color: transparent;
  background-image: none;
  border-color: #223035;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #223035;
  border-color: #223035;
}
.btn-outline-dark.focus,
.btn-outline-dark:focus {
  color: #fff;
  background-color: #223035;
  border-color: #223035;
  box-shadow: none;
}
.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #223035;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #223035;
  border-color: #223035;
}
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-inverse-primary {
  color: #008cff;
  background-color: rgba(0, 140, 255, 0.18);
  border-color: rgb(209, 234, 255);
}

.btn-inverse-primary:hover {
  color: #008cff;
  background-color: rgba(0, 140, 255, 0.18);
  border-color: rgba(0, 140, 255, 0.18);
}

.btn-inverse-secondary {
  color: #75808a;
  background-color: rgba(117, 128, 138, 0.18);
  border-color: rgb(230, 232, 234);
}

.btn-inverse-secondary:hover {
  color: #75808a;
  background-color: rgba(117, 128, 138, 0.18);
  border-color: rgba(117, 128, 138, 0.18);
}

.btn-inverse-success {
  color: #15ca20;
  background-color: rgba(21, 202, 32, 0.18);
  border-color: rgb(212, 246, 214);
}

.btn-inverse-success:hover {
  color: #15ca20;
  background-color: rgba(21, 202, 32, 0.18);
  border-color: rgba(21, 202, 32, 0.18);
}

.btn-inverse-danger {
  color: #fd3550;
  background-color: rgba(253, 53, 80, 0.18);
  border-color: rgb(255, 218, 223);
}

.btn-inverse-danger:hover {
  color: #fd3550;
  background-color: rgba(253, 53, 80, 0.18);
  border-color: rgba(253, 53, 80, 0.18);
}

.btn-inverse-warning {
  color: #ff9700;
  background-color: rgba(255, 151, 0, 0.18);
  border-color: rgb(255, 236, 209);
}

.btn-inverse-warning:hover {
  color: #ff9700;
  background-color: rgba(255, 151, 0, 0.18);
  border-color: rgba(255, 151, 0, 0.18);
}

.btn-inverse-info {
  color: #0dceec;
  background-color: rgba(13, 206, 236, 0.18);
  border-color: rgb(211, 246, 252);
}

.btn-inverse-info:hover {
  color: #0dceec;
  background-color: rgba(13, 206, 236, 0.18);
  border-color: rgba(13, 206, 236, 0.18);
}

.btn-inverse-light {
  color: #a7aaaa;
  background-color: rgba(233, 234, 234, 0.2);
  border-color: rgb(251, 251, 251);
}

.btn-inverse-light:hover {
  color: #a7aaaa;
  background-color: rgba(233, 234, 234, 0.2);
  border-color: rgba(233, 234, 234, 0.2);
}

.btn-inverse-dark {
  color: #223035;
  background-color: rgba(34, 48, 53, 0.2);
  border-color: #d7d9da;
}

.btn-inverse-dark:hover {
  color: #223035;
  background-color: rgba(34, 48, 53, 0.2);
  border-color: rgba(34, 48, 53, 0.2);
}

.btn-gradient-bloody {
  background: #f54ea2;
  background: -webkit-linear-gradient(45deg, #f54ea2, #ff7676) !important;
  background: linear-gradient(45deg, #f54ea2, #ff7676) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-scooter {
  background: #17ead9;
  background: -webkit-linear-gradient(45deg, #17ead9, #6078ea) !important;
  background: linear-gradient(45deg, #17ead9, #6078ea) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-blooker {
  background: #ffdf40;
  background: -webkit-linear-gradient(45deg, #ffdf40, #ff8359) !important;
  background: linear-gradient(45deg, #ffdf40, #ff8359) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-quepal {
  background: #42e695;
  background: -webkit-linear-gradient(45deg, #42e695, #3bb2b8) !important;
  background: linear-gradient(45deg, #42e695, #3bb2b8) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-purpink {
  background: #7f00ff;
  background: -webkit-linear-gradient(45deg, #7f00ff, #e100ff) !important;
  background: linear-gradient(45deg, #7f00ff, #e100ff) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-ibiza {
  background: #ee0979;
  background: -webkit-linear-gradient(45deg, #ee0979, #ff6a00) !important;
  background: linear-gradient(45deg, #ee0979, #ff6a00) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-dusk {
  background: #2c3e50;
  background: -webkit-linear-gradient(45deg, #2c3e50, #fd746c) !important;
  background: linear-gradient(45deg, #2c3e50, #fd746c) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-meridian {
  background: #ff446a;
  background: -webkit-linear-gradient(45deg, #283c86, #45a247) !important;
  background: linear-gradient(45deg, #283c86, #45a247) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-violet {
  background: #4776e6;
  background: -webkit-linear-gradient(45deg, #4776e6, #8e54e9) !important;
  background: linear-gradient(45deg, #4776e6, #8e54e9) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-titanium {
  background: #283048;
  background: -webkit-linear-gradient(45deg, #283048, #859398) !important;
  background: linear-gradient(45deg, #283048, #859398) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-shifter {
  background: #bc4e9c;
  background: -webkit-linear-gradient(45deg, #bc4e9c, #f80759) !important;
  background: linear-gradient(45deg, #bc4e9c, #f80759) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-army {
  background: #414d0b;
  background: -webkit-linear-gradient(45deg, #414d0b, #727a17) !important;
  background: linear-gradient(45deg, #414d0b, #727a17) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-orange {
  background: #fc4a1a;
  background: -webkit-linear-gradient(45deg, #fc4a1a, #f7b733) !important;
  background: linear-gradient(45deg, #fc4a1a, #f7b733) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-yoda {
  background: #ff0099;
  background: -webkit-linear-gradient(45deg, #ff0099, #493240) !important;
  background: linear-gradient(45deg, #ff0099, #493240) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-royal {
  background: #141e30;
  background: -webkit-linear-gradient(45deg, #141e30, #2c527d) !important;
  background: linear-gradient(45deg, #141e30, #2c527d) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-knight {
  background: #ba8b02;
  background: -webkit-linear-gradient(45deg, #ba8b02, #181818) !important;
  background: linear-gradient(45deg, #ba8b02, #181818) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-forest {
  background: #000428;
  background: -webkit-linear-gradient(45deg, #000428, #004e92) !important;
  background: linear-gradient(45deg, #000428, #004e92) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-redmist {
  background: #000000;
  background: -webkit-linear-gradient(45deg, #000000, #e74c3c) !important;
  background: linear-gradient(45deg, #000000, #e74c3c) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-steelgray {
  background: #1f1c2c;
  background: -webkit-linear-gradient(45deg, #1f1c2c, #928dab) !important;
  background: linear-gradient(45deg, #1f1c2c, #928dab) !important;
  color: #fff;
  border: 0;
}

.btn-gradient-influenza {
  background: #c04848;
  background: -webkit-linear-gradient(45deg, #c04848, #480048) !important;
  background: linear-gradient(45deg, #c04848, #480048) !important;
  color: #fff;
  border: 0;
}

.btn-round {
  border-radius: 30px !important;
}

.btn-square {
  border-radius: 0px !important;
}

.btn-group,
.btn-group-vertical {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.btn-group .btn {
  box-shadow: none !important;
}

.btn-group-vertical .btn {
  box-shadow: none !important;
}

.btn-group.group-round > .btn:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
.btn-group.group-round {
  border-radius: 25px;
}
.btn-group.group-round > .btn:last-child {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.btn-group-vertical.group-round-vertical {
  border-radius: 25px;
}
.btn-group-vertical.group-round-vertical > .btn:first-child {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.btn-group-vertical.group-round-vertical > .btn:last-child {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.split-btn-primary,
.split-btn-primary:hover {
  border: 1px solid #0268d6 !important;
  background-color: #0268d6;
}

.split-btn-success,
.split-btn-success:hover {
  border: 1px solid #0f942e !important;
  background-color: #0f942e;
}

.split-btn-danger,
.split-btn-danger:hover {
  border: 1px solid #d02d3c !important;
  background-color: #d02d3c;
}

.split-btn-secondary,
.split-btn-secondary:hover {
  border: 1px solid #586169 !important;
  background-color: #586169;
}

.split-btn-warning,
.split-btn-warning:hover {
  border: 1px solid #e68901 !important;
  background-color: #e68901;
}

.split-btn-info,
.split-btn-info:hover {
  border: 1px solid #0296ad !important;
  background-color: #0296ad;
}

.split-btn-dark,
.split-btn-dark:hover {
  border: 1px solid #000000 !important;
  background-color: #000000;
}

.btn-social {
  width: 42px;
  height: 42px;
  font-size: 16px;
  line-height: 42px;
  display: inline-block;
  border: 1px solid transparent;
  background: #ddd;
  color: #fff;
  text-align: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}

.btn-social:hover,
.btn-social:focus,
.btn-social:active {
  outline: 0;
  color: #fff;
}

.btn-social-text {
  font-size: 0.72rem;
  text-transform: uppercase;
  padding: 10px 20px;
  font-weight: 600;
  border: 1px solid transparent;
  display: inline-block;
  background: #ddd;
  color: #fff;
  text-align: center;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
  -o-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s;
}
.btn-social-text i {
  font-size: 0.95rem;
  padding-right: 4px;
  vertical-align: middle;
  display: table-cell;
}

.btn-social-text span {
  display: table-cell;
  vertical-align: middle;
}

.btn-social-text:hover,
.btn-social:focus,
.btn-social:active {
  outline: 0;
  color: #fff;
}

.btn-social-circle {
  border-radius: 50%;
}

.btn-outline-facebook {
  box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14),
    0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
  color: #3b5998;
  background-color: #fff;
  border-color: #3b5998;
}

.btn-outline-facebook:hover,
.btn-outline-facebook:focus {
  box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14),
    0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
  color: #fff;
  background-color: #3b5998;
}

.btn-outline-twitter {
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
  color: #55acee;
  background-color: #fff;
  border-color: #55acee;
}

.btn-outline-twitter:hover,
.btn-outline-twitter:focus {
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
  background-color: #55acee;
  color: #fff;
}

.btn-outline-google-plus {
  box-shadow: 0 2px 2px 0 rgba(221, 75, 57, 0.14),
    0 3px 1px -2px rgba(221, 75, 57, 0.2), 0 1px 5px 0 rgba(221, 75, 57, 0.12);
  color: #dd4b39;
  background-color: #fff;
  border-color: #dd4b39;
}

.btn-outline-google-plus:hover,
.btn-outline-google-plus:focus {
  box-shadow: 0 2px 2px 0 rgba(221, 75, 57, 0.14),
    0 3px 1px -2px rgba(221, 75, 57, 0.2), 0 1px 5px 0 rgba(221, 75, 57, 0.12);
  background-color: #dd4b39;
  color: #fff;
}

.btn-outline-linkedin {
  box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14),
    0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);
  background-color: #fff;
  color: #0976b4;
  border-color: #0976b4;
}

.btn-outline-linkedin:hover,
.btn-outline-linkedin:focus {
  box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14),
    0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);
  background-color: #0976b4;
  color: #fff;
}

.btn-outline-pinterest {
  box-shadow: 0 2px 2px 0 rgba(204, 33, 39, 0.14),
    0 3px 1px -2px rgba(204, 33, 39, 0.2), 0 1px 5px 0 rgba(204, 33, 39, 0.12);
  color: #cc2127;
  background-color: #fff;
  border-color: #cc2127;
}

.btn-outline-pinterest:hover,
.btn-outline-pinterest:focus {
  box-shadow: 0 2px 2px 0 rgba(204, 33, 39, 0.14),
    0 3px 1px -2px rgba(204, 33, 39, 0.2), 0 1px 5px 0 rgba(204, 33, 39, 0.12);
  background-color: #cc2127;
  color: #fff;
}

.btn-outline-youtube {
  box-shadow: 0 2px 2px 0 rgba(229, 45, 39, 0.14),
    0 3px 1px -2px rgba(229, 45, 39, 0.2), 0 1px 5px 0 rgba(229, 45, 39, 0.12);
  color: #e52d27;
  background-color: #fff;
  border-color: #e52d27;
}

.btn-outline-youtube:hover,
.btn-outline-youtube:focus {
  box-shadow: 0 2px 2px 0 rgba(229, 45, 39, 0.14),
    0 3px 1px -2px rgba(229, 45, 39, 0.2), 0 1px 5px 0 rgba(229, 45, 39, 0.12);
  background-color: #e52d27;
  color: #fff;
}

.btn-outline-tumblr {
  box-shadow: 0 2px 2px 0 rgba(53, 70, 92, 0.14),
    0 3px 1px -2px rgba(53, 70, 92, 0.2), 0 1px 5px 0 rgba(53, 70, 92, 0.12);
  color: #35465c;
  background-color: #fff;
  border-color: #35465c;
}

.btn-outline-tumblr:hover,
.btn-outline-tumblr:focus {
  box-shadow: 0 2px 2px 0 rgba(53, 70, 92, 0.14),
    0 3px 1px -2px rgba(53, 70, 92, 0.2), 0 1px 5px 0 rgba(53, 70, 92, 0.12);
  background-color: #35465c;
  color: #fff;
}

.btn-outline-github {
  box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14),
    0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
  color: #333333;
  background-color: #fff;
  border-color: #333333;
}

.btn-outline-github:hover,
.btn-github:focus {
  box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14),
    0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
  background-color: #333333;
  color: #fff;
}

.btn-outline-behance {
  box-shadow: 0 2px 2px 0 rgba(23, 105, 255, 0.14),
    0 3px 1px -2px rgba(23, 105, 255, 0.2), 0 1px 5px 0 rgba(23, 105, 255, 0.12);
  color: #1769ff;
  background-color: #fff;
  border-color: #1769ff;
}

.btn-outline-behance:hover,
.btn-outline-behance:focus {
  box-shadow: 0 2px 2px 0 rgba(23, 105, 255, 0.14),
    0 3px 1px -2px rgba(23, 105, 255, 0.2), 0 1px 5px 0 rgba(23, 105, 255, 0.12);
  background-color: #1769ff;
  color: #fff;
}

.btn-outline-dribbble {
  box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14),
    0 3px 1px -2px rgba(234, 76, 137, 0.2), 0 1px 5px 0 rgba(234, 76, 137, 0.12);
  color: #ea4c89;
  background-color: #fff;
  border-color: #ea4c89;
}

.btn-outline-dribbble:hover,
.btn-outline-dribbble:focus {
  box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14),
    0 3px 1px -2px rgba(234, 76, 137, 0.2), 0 1px 5px 0 rgba(234, 76, 137, 0.12);
  background-color: #ea4c89;
  color: #fff;
}

.btn-outline-skype {
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
  color: #00aff0;
  background-color: #fff;
  border-color: #00aff0;
}

.btn-outline-skype:hover,
.btn-outline-skype:focus {
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
  background-color: #00aff0;
  color: #fff;
}

.btn-facebook {
  box-shadow: 0 2px 2px 0 rgba(59, 89, 152, 0.14),
    0 3px 1px -2px rgba(59, 89, 152, 0.2), 0 1px 5px 0 rgba(59, 89, 152, 0.12);
  background-color: #3b5998;
  border-color: #3b5998;
}
.btn-twitter {
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
  background-color: #55acee;
  border-color: #55acee;
}

.btn-google-plus {
  box-shadow: 0 2px 2px 0 rgba(221, 75, 57, 0.14),
    0 3px 1px -2px rgba(221, 75, 57, 0.2), 0 1px 5px 0 rgba(221, 75, 57, 0.12);
  background-color: #dd4b39;
  border-color: #dd4b39;
}
.btn-linkedin {
  box-shadow: 0 2px 2px 0 rgba(9, 118, 180, 0.14),
    0 3px 1px -2px rgba(9, 118, 180, 0.2), 0 1px 5px 0 rgba(9, 118, 180, 0.12);
  background-color: #0976b4;
  border-color: #0976b4;
}
.btn-pinterest {
  box-shadow: 0 2px 2px 0 rgba(204, 33, 39, 0.14),
    0 3px 1px -2px rgba(204, 33, 39, 0.2), 0 1px 5px 0 rgba(204, 33, 39, 0.12);
  background-color: #cc2127;
  border-color: #cc2127;
}
.btn-youtube {
  box-shadow: 0 2px 2px 0 rgba(229, 45, 39, 0.14),
    0 3px 1px -2px rgba(229, 45, 39, 0.2), 0 1px 5px 0 rgba(229, 45, 39, 0.12);
  background-color: #e52d27;
  border-color: #e52d27;
}
.btn-tumblr {
  box-shadow: 0 2px 2px 0 rgba(53, 70, 92, 0.14),
    0 3px 1px -2px rgba(53, 70, 92, 0.2), 0 1px 5px 0 rgba(53, 70, 92, 0.12);
  background-color: #35465c;
  border-color: #35465c;
}
.btn-github {
  box-shadow: 0 2px 2px 0 rgba(51, 51, 51, 0.14),
    0 3px 1px -2px rgba(51, 51, 51, 0.2), 0 1px 5px 0 rgba(51, 51, 51, 0.12);
  background-color: #333333;
  border-color: #333333;
}
.btn-behance {
  box-shadow: 0 2px 2px 0 rgba(23, 105, 255, 0.14),
    0 3px 1px -2px rgba(23, 105, 255, 0.2), 0 1px 5px 0 rgba(23, 105, 255, 0.12);
  background-color: #1769ff;
  border-color: #1769ff;
}
.btn-dribbble {
  box-shadow: 0 2px 2px 0 rgba(234, 76, 137, 0.14),
    0 3px 1px -2px rgba(234, 76, 137, 0.2), 0 1px 5px 0 rgba(234, 76, 137, 0.12);
  background-color: #ea4c89;
  border-color: #ea4c89;
}
.btn-skype {
  box-shadow: 0 2px 2px 0 rgba(85, 172, 238, 0.14),
    0 3px 1px -2px rgba(85, 172, 238, 0.2), 0 1px 5px 0 rgba(85, 172, 238, 0.12);
  background-color: #00aff0;
  border-color: #00aff0;
}

#calendar {
  margin: 0 auto;
  width: 100%;
  background-color: #ffffff;
  border-radius: 2px;
  border: 1px solid #f1f1f1;
}

table.grid {
  width: 100%;
  border: none;
  background-color: #f1f1f1;
  padding: 0px;
}

table.grid tr {
  text-align: center;
}

table.grid td {
  border: 4px solid white;
  padding: 8px;
}

/* User Cards */
.user-box {
  width: 110px;
  margin: auto;
  margin-bottom: 20px;
}

.user-box img {
  width: 100%;
  border-radius: 50%;
  padding: 3px;
  background: #fff;
  -webkit-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  -ms-box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
}

.profile-card-2 .card {
  position: relative;
}

.profile-card-2 .card .card-body {
  z-index: 1;
}

.profile-card-2 .card::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  height: 112px;
  background-color: #e6e6e6;
}

.profile-card-2 .card.profile-primary::before {
  background: linear-gradient(45deg, #ee0979, #ff6a00) !important;
}
.profile-card-2 .card.profile-success::before {
  background-color: #15ca20;
}
.profile-card-2 .card.profile-danger::before {
  background-color: #fd3550;
}
.profile-card-2 .card.profile-warning::before {
  background-color: #ff9700;
}
.profile-card-2 .user-box {
  margin-top: 30px;
}

.profile-card-3 .user-fullimage {
  position: relative;
}

.profile-card-3 .user-fullimage .details {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
}

.profile-card-4 .user-box {
  width: 110px;
  margin: auto;
  margin-bottom: 10px;
  margin-top: 15px;
}

.profile-card-4 .list-icon {
  display: table-cell;
  font-size: 30px;
  padding-right: 20px;
  vertical-align: middle;
  color: #223035;
}

.profile-card-4 .list-details {
  display: table-cell;
  vertical-align: middle;
  font-weight: 600;
  color: #223035;
  font-size: 15px;
  line-height: 15px;
}

.profile-card-4 .list-details small {
  display: table-cell;
  vertical-align: middle;
  font-size: 12px;
  font-weight: 400;
  color: #808080;
}

.icon a:hover {
  background: #03a9f4;
  color: #fff;
  text-decoration: none;
}

.icon a {
  display: block;
  color: #424242;
  padding: 8px;
  line-height: 32px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 2px;
}

.icon a i {
  padding-right: 10px;
}

.icon-section {
  clear: both;
  overflow: hidden;
}
.icon-container {
  width: 240px;
  padding: 0.7em 0;
  float: left;
  position: relative;
  text-align: left;
}
.icon-container [class^="ti-"],
.icon-container [class*=" ti-"] {
  color: #000;
  position: absolute;
  margin-top: 3px;
  transition: 0.3s;
}
.icon-container:hover [class^="ti-"],
.icon-container:hover [class*=" ti-"] {
  font-size: 2.2em;
  margin-top: -5px;
}
.icon-container:hover .icon-name {
  color: #000;
}
.icon-name {
  color: #424242;
  margin-left: 35px;
  transition: 0.3s;
}
.icon-container:hover .icon-name {
  margin-left: 45px;
}

.preview {
  padding: 15px 0;
  position: relative;
}

.show-code {
  color: #101010;
}

.icons {
  font-size: 15px;
  padding-right: 7px;
}

.name {
  font-size: 15px;
}

.preview a {
  padding: 15px;
}
.preview a:hover {
  padding: 15px;
  text-decoration: none;
}

.preview a i {
  margin-right: 10px;
  font-size: 18px;
}

.icon-preview-box div:hover {
  background: #eef5f9;
}

.flag-icon-list div {
  cursor: pointer;
  line-height: 60px;
  white-space: nowrap;
  color: #424242;
}

.flag-icon-list div:hover {
  background: #eef5f9;
}

.flag-icon-list i {
  -webkit-transition: all 0.2s;
  -webkit-transition: font-size 0.2s;
  display: inline-block;
  font-size: 18px;
  margin: 0 15px 0 10px;
  text-align: left;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -o-transition: font-size 0.2s;
  transition: font-size 0.2s;
  vertical-align: middle;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

/*Widgets*/
.media-icons {
  left: 0;
  top: 0;
  position: absolute;
  line-height: 60px;
  text-align: center;
}

.media-icons span {
  left: 0;
  position: absolute;
  width: 70px;
  height: 70px;
  border-bottom-right-radius: 70px;
  border-top-left-radius: 4px;
}
.media-icons i {
  position: relative;
  font-size: 25px;
  padding-left: 15px;
  color: #fff;
}

.media-icons-big {
  left: 0;
  top: 0;
  position: absolute;
  line-height: 60px;
  text-align: center;
}

.media-icons-big span {
  position: absolute;
  left: 0;
  width: 120px;
  height: 118px;
  background: rgba(255, 255, 255, 0.25);
  border-bottom-right-radius: 130px;
}
.media-icons-big i {
  position: relative;
  font-size: 40px;
  padding-left: 30px;
  color: #fff;
  top: 30px;
}

.w-circle-icon {
  width: 65px;
  height: 65px;
  text-align: center;
}

.w-circle-icon i {
  position: relative;
  top: 18px;
  font-size: 28px;
}

.media .w-icon i {
  font-size: 44px;
}

.chart {
  position: relative;
  display: inline-block;
  width: 110px;
  height: 110px;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
.chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.chart.chart-widget-pie {
  margin-top: 5px;
  margin-bottom: 5px;
}
.percent {
  display: inline-block;
  line-height: 110px;
  z-index: 2;
}
.percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 0.8em;
}

.w_chart {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  text-align: center;
}
.w_chart canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.w_percent {
  display: inline-block;
  line-height: 80px;
  z-index: 2;
  color: #000;
}
.w_percent:after {
  content: "%";
  margin-left: 0.1em;
  font-size: 0.8em;
  color: #000;
}

.float-chart-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 300px;
  max-height: 100%;
}

.float-chart {
  width: 100%;
  position: relative;
  max-width: none;
  height: 300px;
}

.gmaps,
.gmaps-panaroma {
  height: 400px;
  background: #eeeeee;
  border-radius: 3px;
}

.error {
  color: #ff0000;
}
label {
  color: #6b6b6b;
  font-size: 0.75rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px;
}

/* Forms */
.input-group .btn {
  box-shadow: none;
}

.col-form-label {
  font-size: 13px;
}

.form-control-rounded {
  border-radius: 30px !important;
}

.form-control-square {
  border-radius: 0px !important;
}

.form-control-xl {
  height: 60px !important;
  font-size: 26px !important;
}

.position-relative {
  position: relative !important;
}

.has-icon-left .form-control {
  padding-right: 0.85rem;
  padding-left: 2.9rem;
}

.form-control-position {
  position: absolute;
  top: -8px;
  right: 0;
  z-index: 2;
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
}

.has-icon-left .form-control-position {
  right: auto;
  left: 0px;
}

.has-icon-right .form-control-position {
  right: 0px;
  left: auto;
}

.has-icon-right .form-control {
  padding-right: 37px;
}

form .form-header {
  color: #223035;
  line-height: 3rem;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 25px;
  border-bottom: 1px solid #223035;
}

form .form-footer {
  border-top: 1px solid #d3dce9;
  padding: 20px 0 10px 0;
  margin-top: 20px;
}

form.form-bordered .form-group {
  margin: 0;
  border-bottom: 1px solid #ebeff5;
}

form.form-bordered .form-group > div {
  padding: 20px;
  padding-right: 0;
  border-left: 1px solid #ebeff5;
}

form.form-bordered .form-group .col-form-label {
  padding-top: 30px;
}

form.striped-rows .form-group:nth-child(2n) {
  background-color: #f4f5fa;
}

form.striped-rows .form-group {
  margin: 0;
}
.search-input {
  margin-bottom: 10px;
}
.custom-header {
  background: #484545;
  padding: 5px;
  color: white;
}

.input-group-prepend [type="checkbox"]:checked,
.input-group-prepend [type="checkbox"]:not(:checked),
.input-group-prepend [type="radio"]:checked,
.input-group-prepend [type="radio"]:not(:checked) {
  position: initial;
  opacity: 1;
  margin-top: 0px;
}

.wizard > .content {
  min-height: 400px;
}

.dropzone {
  min-height: 230px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  background: white;
  border-radius: 6px;
}
.dropzone .dz-message {
  font-size: 30px;
}

.border-radius {
  border-radius: 0px;
}

.payment-icons img {
  width: 100px;
}
.bootstrap-touchspin .input-group-text {
  border-radius: 0px;
}

.datepicker table tr td,
.datepicker table tr th {
  width: 40px;
  height: 40px;
}

.user-lock {
  height: 150px !important;
}

.user-lock-img {
  width: 130px;
  margin: auto;
}

.user-lock-img img {
  width: 100%;
  border-radius: 50%;
  margin-top: 80px;
  border: 4px solid white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

/* Tables */
.table-responsive {
  white-space: nowrap;
}
.table .thead-primary th {
  color: #fff;
  background-color: #008cff;
  border-color: #0376f1;
}

.table .thead-secondary th {
  color: #fff;
  background-color: #75808a;
  border-color: #56616b;
}

.table .thead-success th {
  color: #fff;
  background-color: #15ca20;
  border-color: #03af0d;
}

.table .thead-danger th {
  color: #fff;
  background-color: #fd3550;
  border-color: #cc011c;
}

.table .thead-warning th {
  color: #fff;
  background-color: #ff9700;
  border-color: #da8100;
}

.table .thead-info th {
  color: #fff;
  background-color: #0dceec;
  border-color: #01b9d6;
}

.table .thead-dark th {
  color: #fff;
  background-color: #223035;
  border-color: #000000;
}

.table .thead-light th {
  color: #495057;
  background-color: #e9eaea;
  border-color: #d6d6d6;
}

.table-primary {
  color: #fff;
  background-color: #008cff;
}

.table-primary td,
.table-primary th,
.table-primary thead th {
  border-color: rgba(244, 245, 250, 0.25);
}

.table-secondary {
  color: #fff;
  background-color: #75808a;
}

.table-secondary td,
.table-secondary th,
.table-secondary thead th {
  border-color: rgba(244, 245, 250, 0.25);
}

.table-success {
  color: #fff;
  background-color: #15ca20;
}

.table-success td,
.table-success th,
.table-success thead th {
  border-color: rgba(244, 245, 250, 0.25);
}

.table-danger {
  color: #fff;
  background-color: #fd3550;
}

.table-danger td,
.table-danger th,
.table-danger thead th {
  border-color: rgba(244, 245, 250, 0.25);
}

.table-warning {
  color: #fff;
  background-color: #ff9700;
}
.table-warning td,
.table-warning th,
.table-warning thead th {
  border-color: rgba(244, 245, 250, 0.54);
}

.table-info {
  color: #fff;
  background-color: #0dceec;
}
.table-info td,
.table-info th,
.table-info thead th {
  border-color: rgba(244, 245, 250, 0.25);
}
.table-dark {
  color: #fff;
  background-color: #223035;
}
.table-dark td,
.table-dark th,
.table-dark thead th {
  border-color: rgba(244, 245, 250, 0.25);
}
.table-light {
  color: #212529;
  background-color: #e9eaea;
}
.table-light td,
.table-light th,
.table-light thead th {
  border-color: rgb(221, 222, 222);
}
.table-active,
.table-active > td,
.table-active > th {
  background-color: rgba(0, 0, 0, 0.02);
}

/* Alerts*/
.alert {
  position: relative;
  padding: 0;
  margin-bottom: 1rem;
  border: none;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border-radius: 0.25rem;
}

.alert .alert-icon {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 50px;
  font-size: 25px;
}

.alert .alert-message {
  display: table-cell;
  padding: 15px 15px 15px 10px;
  font-size: 13px;
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 15px;
  color: inherit;
}

.alert .contrast-alert {
  background-color: rgba(255, 255, 255, 0.2);
}

.alert-success {
  color: #ffffff;
  background-color: #15ca20;
  border-color: #15ca20;
}
.alert-success .alert-link {
  color: #7bff2b;
}

.alert-info {
  color: #fefefe;
  background-color: #0dceec;
  border-color: #0dceec;
}
.alert-info .alert-link {
  color: #bef6ff;
}
.alert-danger {
  color: #ffffff;
  background-color: #fd3550;
  border-color: #fd3550;
}
.alert-danger .alert-link {
  color: #ffcacf;
}

.alert-warning {
  color: #fff;
  background-color: #ff9700;
  border-color: #ff9700;
}
.alert-warning .alert-link {
  color: #fff900;
}

.alert-outline-success {
  color: #15ca20;
  background-color: transparent;
  border: 1px solid #15ca20;
  border-right: 4px solid #15ca20;
  border-left: 4px solid #15ca20;
}
.alert-outline-success .alert-link {
  color: #15ca20;
}

.alert-outline-info {
  color: #0dceec;
  background-color: transparent;
  border: 1px solid #0dceec;
  border-right: 4px solid #0dceec;
  border-left: 4px solid #0dceec;
}
.alert-outline-info .alert-link {
  color: #0dceec;
}

.alert-outline-danger {
  color: #fd3550;
  background-color: transparent;
  border: 1px solid #fd3550;
  border-right: 4px solid #fd3550;
  border-left: 4px solid #fd3550;
}
.alert-outline-danger .alert-link {
  color: #fd3550;
}

.alert-outline-warning {
  color: #ff9700;
  background-color: transparent;
  border: 1px solid #ff9700;
  border-right: 4px solid #ff9700;
  border-left: 4px solid #ff9700;
}
.alert-outline-warning .alert-link {
  color: #ff9700;
}

.alert-icon-success {
  color: #15ca20;
  background-color: transparent;
  border: 1px solid #15ca20;
}
.icon-part-success {
  background: #15ca20;
  color: #fff;
  border-right: 1px solid #08b713;
}

.alert-icon-success .alert-link {
  color: #15ca20;
}

.alert-icon-info {
  color: #0dceec;
  background-color: transparent;
  border: 1px solid #0dceec;
}
.icon-part-info {
  background: #0dceec;
  color: #fff;
  border-right: 1px solid #09b9d4;
}
.alert-icon-info .alert-link {
  color: #0dceec;
}

.alert-icon-danger {
  color: #fd3550;
  background-color: transparent;
  border: 1px solid #fd3550;
}
.icon-part-danger {
  background: #fd3550;
  color: #fff;
  border-right: 1px solid #de2840;
}
.alert-icon-danger .alert-link {
  color: #fd3550;
}

.alert-icon-warning {
  color: #ff9700;
  background-color: transparent;
  border: 1px solid #ff9700;
}
.icon-part-warning {
  background: #ff9700;
  color: #fff;
  border-right: 1px solid #e08500;
}
.alert-icon-warning .alert-link {
  color: #ff9700;
}

.alert-round {
  border-radius: 30px;
}

.icon-part-round {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.icon-part-round-full {
  border-radius: 30px;
}

.alert-light-success {
  color: #15ca20;
  background-color: rgba(7, 255, 22, 0.24);
  border-color: rgb(212, 246, 214);
}
.alert-light-success .alert-link {
  color: #15ca20;
}

.alert-light-info {
  color: #0dceec;
  background-color: rgba(2, 221, 255, 0.24);
  border-color: rgb(211, 246, 252);
}
.alert-light-info .alert-link {
  color: #0dceec;
}
.alert-light-danger {
  color: #fd3550;
  background-color: rgba(255, 72, 97, 0.24);
  border-color: rgb(255, 218, 223);
}
.alert-light-danger .alert-link {
  color: #fd3550;
}

.alert-light-warning {
  color: #ff9700;
  background-color: rgba(255, 195, 0, 0.24);
  border-color: rgb(255, 236, 209);
}
.alert-light-warning .alert-link {
  color: #ff9700;
}

/*Badges*/
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-up {
  position: absolute;
  top: 2px;
  right: 2px;
  border-radius: 50%;
  font-size: 12px;
}
.badge-primary {
  color: #fff;
  background-color: #008cff !important;
}
.badge-secondary {
  color: #fff;
  background-color: #75808a !important;
}
.badge-success {
  color: #fff;
  background-color: #15ca20 !important;
}
.badge-danger {
  color: #fff;
  background-color: #fd3550 !important;
}
.badge-warning {
  color: #fff !important;
  background-color: #ff9700 !important;
}
.badge-info {
  color: #fff;
  background-color: #0dceec;
}
.badge-light {
  color: #212529;
  background-color: #e9eaea;
}
.badge-dark {
  color: #fff;
  background-color: #223035;
}

/* Paginations */
.pagination {
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #008cff;
  background-color: #fff;
  border: 1px solid #dee2e6;
  box-shadow: 0 0.125rem 0.25rem rgba(80, 73, 73, 0.06);
}
.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #008cff;
  border-color: #008cff;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}
.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-round .page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
}

.pagination-round .page-item:last-child .page-link {
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
}

.pagination-separate .page-item .page-link {
  margin-left: 4px;
}

.pagination-outline-primary .page-item .page-link {
  color: #008cff;
  border: 1px solid #008cff;
}
.pagination-outline-primary .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #008cff;
  border-color: #008cff;
}
.pagination-outline-primary .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.pagination-outline-primary .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #008cff;
  border-color: #008cff;
}

.pagination-outline-primary .page-item.disabled .page-link,
.pagination-outline-success .page-item.disabled .page-link,
.pagination-outline-info .page-item.disabled .page-link,
.pagination-outline-danger .page-item.disabled .page-link,
.pagination-outline-warning .page-item.disabled .page-link,
.pagination-outline-dark .page-item.disabled .page-link,
.pagination-outline-secondary .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-outline-success .page-item .page-link {
  color: #15ca20;
  border: 1px solid #15ca20;
}
.pagination-outline-success .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #15ca20;
  border-color: #15ca20;
}
.pagination-outline-success .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(21, 202, 32, 0.3);
}
.pagination-outline-success .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #15ca20;
  border-color: #15ca20;
}

.pagination-outline-info .page-item .page-link {
  color: #0dceec;
  border: 1px solid #0dceec;
}
.pagination-outline-info .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #0dceec;
  border-color: #0dceec;
}
.pagination-outline-info .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 206, 236, 0.18);
}
.pagination-outline-info .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0dceec;
  border-color: #0dceec;
}

.pagination-outline-danger .page-item .page-link {
  color: #fd3550;
  border: 1px solid #fd3550;
}
.pagination-outline-danger .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #fd3550;
  border-color: #fd3550;
}
.pagination-outline-danger .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(253, 53, 80, 0.25);
}
.pagination-outline-danger .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #fd3550;
  border-color: #fd3550;
}

.pagination-outline-warning .page-item .page-link {
  color: #ff9700;
  border: 1px solid #ff9700;
}
.pagination-outline-warning .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #ff9700;
  border-color: #ff9700;
}
.pagination-outline-warning .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 151, 0, 0.26);
}
.pagination-outline-warning .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #ff9700;
  border-color: #ff9700;
}

.pagination-outline-dark .page-item .page-link {
  color: #223035;
  border: 1px solid #223035;
}
.pagination-outline-dark .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #223035;
  border-color: #223035;
}
.pagination-outline-dark .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 48, 53, 0.45);
}
.pagination-outline-dark .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #223035;
  border-color: #223035;
}

.pagination-outline-secondary .page-item .page-link {
  color: #75808a;
  border: 1px solid #75808a;
}
.pagination-outline-secondary .page-item .page-link:hover {
  z-index: 2;
  color: #fff;
  text-decoration: none;
  background-color: #75808a;
  border-color: #75808a;
}
.pagination-outline-secondary .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(117, 128, 138, 0.3);
}
.pagination-outline-secondary .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #75808a;
  border-color: #75808a;
}

.pagination-primary .page-item .page-link {
  color: #ffffff;
  border: 1px solid #0078da;
  background: #008cff;
}
.pagination-primary .page-item .page-link:hover {
  z-index: 2;
  color: #008cff;
  text-decoration: none;
  background-color: transparent;
  border-color: #0078da;
}
.pagination-primary .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.pagination-primary .page-item.active .page-link {
  z-index: 1;
  color: #008cff;
  background-color: transparent;
  border-color: #0078da;
}

.pagination-primary .page-item.disabled .page-link,
.pagination-success .page-item.disabled .page-link,
.pagination-info .page-item.disabled .page-link,
.pagination-danger .page-item.disabled .page-link,
.pagination-warning .page-item.disabled .page-link,
.pagination-dark .page-item.disabled .page-link,
.pagination-secondary .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-success .page-item .page-link {
  color: #ffffff;
  border: 1px solid #04a50e;
  background-color: #15ca20;
}
.pagination-success .page-item .page-link:hover {
  z-index: 2;
  color: #15ca20;
  text-decoration: none;
  background-color: transparent;
  border-color: #04a50e;
}
.pagination-success .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(21, 202, 32, 0.3);
}
.pagination-success .page-item.active .page-link {
  z-index: 1;
  color: #15ca20;
  background-color: transparent;
  border-color: #04a50e;
}

.pagination-info .page-item .page-link {
  color: #ffffff;
  border: 1px solid #02b0cc;
  background-color: #0dceec;
}
.pagination-info .page-item .page-link:hover {
  z-index: 2;
  color: #0dceec;
  text-decoration: none;
  background-color: transparent;
  border-color: #02b0cc;
}
.pagination-info .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 206, 236, 0.18);
}
.pagination-info .page-item.active .page-link {
  z-index: 1;
  color: #0dceec;
  background-color: transparent;
  border-color: #02b0cc;
}

.pagination-danger .page-item .page-link {
  color: #fff;
  border: 1px solid #d0021d;
  background-color: #fd3550;
}
.pagination-danger .page-item .page-link:hover {
  z-index: 2;
  color: #fd3550;
  text-decoration: none;
  background-color: transparent;
  border-color: #d0021d;
}
.pagination-danger .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(253, 53, 80, 0.25);
}
.pagination-danger .page-item.active .page-link {
  z-index: 1;
  color: #fd3550;
  background-color: transparent;
  border-color: #d0021d;
}

.pagination-warning .page-item .page-link {
  color: #ffffff;
  border: 1px solid #d67f00;
  background-color: #ff9700;
}
.pagination-warning .page-item .page-link:hover {
  z-index: 2;
  color: #ff9700;
  text-decoration: none;
  background-color: transparent;
  border-color: #d67f00;
}
.pagination-warning .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 151, 0, 0.26);
}
.pagination-warning .page-item.active .page-link {
  z-index: 1;
  color: #ff9700;
  background-color: transparent;
  border-color: #d67f00;
}

.pagination-dark .page-item .page-link {
  color: #ffffff;
  border: 1px solid #000000;
  background: #223035;
}
.pagination-dark .page-item .page-link:hover {
  z-index: 2;
  color: #223035;
  text-decoration: none;
  background-color: transparent;
  border-color: #000000;
}
.pagination-dark .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 48, 53, 0.45);
}
.pagination-dark .page-item.active .page-link {
  z-index: 1;
  color: #223035;
  background-color: transparent;
  border-color: #223035;
}

.pagination-secondary .page-item .page-link {
  color: #ffffff;
  border: 1px solid #607282;
  background-color: #75808a;
}
.pagination-secondary .page-item .page-link:hover {
  z-index: 2;
  color: #75808a;
  text-decoration: none;
  background-color: transparent;
  border-color: #607282;
}
.pagination-secondary .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(117, 128, 138, 0.3);
}
.pagination-secondary .page-item.active .page-link {
  z-index: 1;
  color: #75808a;
  background-color: transparent;
  border-color: #607282;
}

.pagination-flat-primary .page-item .page-link {
  color: #008cff;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.pagination-flat-primary .page-item .page-link:hover {
  z-index: 2;
  color: #008cff;
  text-decoration: none;
  background-color: transparent;
}
.pagination-flat-primary .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
.pagination-flat-primary .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #008cff;
}

.pagination-flat-success .page-item .page-link {
  color: #15ca20;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.pagination-flat-success .page-item .page-link:hover {
  z-index: 2;
  color: #15ca20;
  text-decoration: none;
  background-color: transparent;
}
.pagination-flat-success .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(21, 202, 32, 0.3);
}
.pagination-flat-success .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #15ca20;
}

.pagination-flat-info .page-item .page-link {
  color: #0dceec;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.pagination-flat-info .page-item .page-link:hover {
  z-index: 2;
  color: #0dceec;
  text-decoration: none;
  background-color: transparent;
}
.pagination-flat-info .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(13, 206, 236, 0.18);
}
.pagination-flat-info .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #0dceec;
}

.pagination-flat-danger .page-item .page-link {
  color: #fd3550;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.pagination-flat-danger .page-item .page-link:hover {
  z-index: 2;
  color: #fd3550;
  text-decoration: none;
  background-color: transparent;
}
.pagination-flat-danger .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(253, 53, 80, 0.25);
}
.pagination-flat-danger .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #fd3550;
}

.pagination-flat-warning .page-item .page-link {
  color: #ff9700;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.pagination-flat-warning .page-item .page-link:hover {
  z-index: 2;
  color: #ff9700;
  text-decoration: none;
  background-color: transparent;
}
.pagination-flat-warning .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 151, 0, 0.26);
}
.pagination-flat-warning .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #ff9700;
}

.pagination-flat-dark .page-item .page-link {
  color: #223035;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.pagination-flat-dark .page-item .page-link:hover {
  z-index: 2;
  color: #223035;
  text-decoration: none;
  background-color: transparent;
}
.pagination-flat-dark .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(34, 48, 53, 0.45);
}
.pagination-flat-dark .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #223035;
}

.pagination-flat-secondary .page-item .page-link {
  color: #75808a;
  background-color: transparent;
  box-shadow: none;
  border: none;
}
.pagination-flat-secondary .page-item .page-link:hover {
  z-index: 2;
  color: #75808a;
  text-decoration: none;
  background-color: transparent;
}
.pagination-flat-secondary .page-item .page-link:focus {
  z-index: 2;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(117, 128, 138, 0.3);
}
.pagination-flat-secondary .page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #75808a;
}

.pagination-flat-primary .page-item.disabled .page-link,
.pagination-flat-success .page-item.disabled .page-link,
.pagination-flat-info .page-item.disabled .page-link,
.pagination-flat-danger .page-item.disabled .page-link,
.pagination-flat-warning .page-item.disabled .page-link,
.pagination-flat-dark .page-item.disabled .page-link,
.pagination-flat-secondary .page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

/* List Groups */
.list-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #008cff;
  border-color: #008cff;
}

.list-group-item.active-success {
  z-index: 2;
  color: #fff;
  background-color: #15ca20;
  border-color: #15ca20;
}

.list-group-item.active-danger {
  z-index: 2;
  color: #fff;
  background-color: #fd3550;
  border-color: #fd3550;
}

.list-group-item.active-warning {
  z-index: 2;
  color: #fff;
  background-color: #ff9700;
  border-color: #ff9700;
}

.list-group-item.active-info {
  z-index: 2;
  color: #fff;
  background-color: #0dceec;
  border-color: #0dceec;
}

.list-group-item.active-dark {
  z-index: 2;
  color: #fff;
  background-color: #223035;
  border-color: #223035;
}
.list-group-item.active-secondary {
  z-index: 2;
  color: #fff;
  background-color: #75808a;
  border-color: #75808a;
}

.list-group-primary .list-group-item {
  background-color: #008cff;
  color: #fff;
  border: 1px solid #39a3fb;
  box-shadow: inset 0 -1px 0px #0574d1;
}
.list-group-success .list-group-item {
  background-color: #15ca20;
  color: #fff;
  border: 1px solid #16da22;
  box-shadow: inset 0 -1px 0px #0fae18;
}
.list-group-danger .list-group-item {
  background-color: #fd3550;
  color: #fff;
  border: 1px solid #ff6579;
  box-shadow: inset 0 -1px 0px #de2039;
}
.list-group-warning .list-group-item {
  background-color: #ff9700;
  color: #fff;
  border: 1px solid #ffae3b;
  box-shadow: inset 0 -1px 0px #e28907;
}
.list-group-info .list-group-item {
  background-color: #0dceec;
  color: #fff;
  border: 1px solid #0fdfff;
  box-shadow: inset 0 -1px 0px #08afc9;
}
.list-group-dark .list-group-item {
  background-color: #223035;
  color: #fff;
  border: 1px solid #2d424c;
  box-shadow: inset 0 -1px 0px #0f1518;
}
.list-group-secondary .list-group-item {
  background-color: #75808a;
  color: #fff;
  border: 1px solid #8b939a;
  box-shadow: inset 0 -1px 0px #525b62;
}

/*Nav Tabs & Pills */
.nav-tabs .nav-link {
  color: #223035;
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 2px;
  margin-bottom: 0;
  padding: 12px 20px;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover {
  border: 1px solid transparent;
}
.nav-tabs .nav-link i {
  margin-right: 2px;
  font-weight: 600;
}

.top-icon.nav-tabs .nav-link i {
  margin: 0px;
  font-weight: 500;
  display: block;
  font-size: 20px;
  padding: 5px 0;
}

.nav-tabs-primary.nav-tabs {
  border-bottom: 1px solid #008cff;
}

.nav-tabs-primary .nav-link.active,
.nav-tabs-primary .nav-item.show > .nav-link {
  color: #008cff;
  background-color: #fff;
  border-color: #008cff #008cff #fff;
  border-top: 3px solid #008cff;
}

.nav-tabs-success.nav-tabs {
  border-bottom: 1px solid #15ca20;
}

.nav-tabs-success .nav-link.active,
.nav-tabs-success .nav-item.show > .nav-link {
  color: #15ca20;
  background-color: #fff;
  border-color: #15ca20 #15ca20 #fff;
  border-top: 3px solid #15ca20;
}

.nav-tabs-info.nav-tabs {
  border-bottom: 1px solid #0dceec;
}

.nav-tabs-info .nav-link.active,
.nav-tabs-info .nav-item.show > .nav-link {
  color: #0dceec;
  background-color: #fff;
  border-color: #0dceec #0dceec #fff;
  border-top: 3px solid #0dceec;
}

.nav-tabs-danger.nav-tabs {
  border-bottom: 1px solid #fd3550;
}

.nav-tabs-danger .nav-link.active,
.nav-tabs-danger .nav-item.show > .nav-link {
  color: #fd3550;
  background-color: #fff;
  border-color: #fd3550 #fd3550 #fff;
  border-top: 3px solid #fd3550;
}

.nav-tabs-warning.nav-tabs {
  border-bottom: 1px solid #ff9700;
}

.nav-tabs-warning .nav-link.active,
.nav-tabs-warning .nav-item.show > .nav-link {
  color: #ff9700;
  background-color: #fff;
  border-color: #ff9700 #ff9700 #fff;
  border-top: 3px solid #ff9700;
}

.nav-tabs-dark.nav-tabs {
  border-bottom: 1px solid #223035;
}

.nav-tabs-dark .nav-link.active,
.nav-tabs-dark .nav-item.show > .nav-link {
  color: #223035;
  background-color: #fff;
  border-color: #223035 #223035 #fff;
  border-top: 3px solid #223035;
}

.nav-tabs-secondary.nav-tabs {
  border-bottom: 1px solid #75808a;
}
.nav-tabs-secondary .nav-link.active,
.nav-tabs-secondary .nav-item.show > .nav-link {
  color: #75808a;
  background-color: #fff;
  border-color: #75808a #75808a #fff;
  border-top: 3px solid #75808a;
}

.tabs-vertical .nav-tabs .nav-link {
  color: #223035;
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  margin: 2px;
  margin-right: -1px;
  padding: 12px 1px;
  text-transform: uppercase;
  border: 1px solid transparent;
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #dee2e6;
}

.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical .nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: 1px solid #dee2e6;
  border-right: 0;
  border-left: 1px solid #dee2e6;
}

.tabs-vertical-primary.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #008cff;
}

.tabs-vertical-primary.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-primary.tabs-vertical .nav-tabs .nav-link.active {
  color: #008cff;
  background-color: #fff;
  border-color: #008cff #008cff #fff;
  border-bottom: 1px solid #008cff;
  border-right: 0;
  border-left: 3px solid #008cff;
}

.tabs-vertical-success.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #15ca20;
}

.tabs-vertical-success.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-success.tabs-vertical .nav-tabs .nav-link.active {
  color: #15ca20;
  background-color: #fff;
  border-color: #15ca20 #15ca20 #fff;
  border-bottom: 1px solid #15ca20;
  border-right: 0;
  border-left: 3px solid #15ca20;
}

.tabs-vertical-info.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #0dceec;
}

.tabs-vertical-info.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-info.tabs-vertical .nav-tabs .nav-link.active {
  color: #0dceec;
  background-color: #fff;
  border-color: #0dceec #0dceec #fff;
  border-bottom: 1px solid #0dceec;
  border-right: 0;
  border-left: 3px solid #0dceec;
}

.tabs-vertical-danger.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #fd3550;
}

.tabs-vertical-danger.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-danger.tabs-vertical .nav-tabs .nav-link.active {
  color: #fd3550;
  background-color: #fff;
  border-color: #fd3550 #fd3550 #fff;
  border-bottom: 1px solid #fd3550;
  border-right: 0;
  border-left: 3px solid #fd3550;
}

.tabs-vertical-warning.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #ff9700;
}

.tabs-vertical-warning.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-warning.tabs-vertical .nav-tabs .nav-link.active {
  color: #ff9700;
  background-color: #fff;
  border-color: #ff9700 #ff9700 #fff;
  border-bottom: 1px solid #ff9700;
  border-right: 0;
  border-left: 3px solid #ff9700;
}

.tabs-vertical-dark.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #223035;
}

.tabs-vertical-dark.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-dark.tabs-vertical .nav-tabs .nav-link.active {
  color: #223035;
  background-color: #fff;
  border-color: #223035 #223035 #fff;
  border-bottom: 1px solid #223035;
  border-right: 0;
  border-left: 3px solid #223035;
}

.tabs-vertical-secondary.tabs-vertical .nav-tabs {
  border: 0;
  border-right: 1px solid #75808a;
}

.tabs-vertical-secondary.tabs-vertical .nav-tabs .nav-item.show .nav-link,
.tabs-vertical-secondary.tabs-vertical .nav-tabs .nav-link.active {
  color: #75808a;
  background-color: #fff;
  border-color: #75808a #75808a #fff;
  border-bottom: 1px solid #75808a;
  border-right: 0;
  border-left: 3px solid #75808a;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
  color: #223035;
  font-size: 12px;
  text-align: center;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 3px;
  padding: 12px 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.nav-pills .nav-link:hover {
  background-color: #f4f5fa;
}

.nav-pills .nav-link i {
  margin-right: 2px;
  font-weight: 600;
}

.top-icon.nav-pills .nav-link i {
  margin: 0px;
  font-weight: 500;
  display: block;
  font-size: 20px;
  padding: 5px 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #008cff;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(0, 140, 255, 0.5);
}

.nav-pills-success .nav-link.active,
.nav-pills-success .show > .nav-link {
  color: #fff;
  background-color: #15ca20;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(21, 202, 32, 0.5);
}

.nav-pills-info .nav-link.active,
.nav-pills-info .show > .nav-link {
  color: #fff;
  background-color: #0dceec;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(13, 206, 236, 0.5);
}

.nav-pills-danger .nav-link.active,
.nav-pills-danger .show > .nav-link {
  color: #fff;
  background-color: #fd3550;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(253, 53, 80, 0.5);
}

.nav-pills-warning .nav-link.active,
.nav-pills-warning .show > .nav-link {
  color: #fff;
  background-color: #ff9700;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(255, 151, 0, 0.5);
}

.nav-pills-dark .nav-link.active,
.nav-pills-dark .show > .nav-link {
  color: #fff;
  background-color: #223035;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(34, 48, 53, 0.5);
}

.nav-pills-secondary .nav-link.active,
.nav-pills-secondary .show > .nav-link {
  color: #fff;
  background-color: #75808a;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
    0 7px 10px -5px rgba(117, 128, 138, 0.5);
}
.card .tab-content {
  padding: 1rem 0 0 0;
}

/* Accordions */
#accordion1 .card-header button:before,
#accordion2 .card-header button:before,
#accordion3 .card-header button:before,
#accordion4 .card-header button:before,
#accordion5 .card-header button:before,
#accordion6 .card-header button:before,
#accordion7 .card-header button:before,
#accordion8 .card-header button:before {
  float: left !important;
  font-family: FontAwesome;
  content: "\f107";
  padding-right: 15px;
}

#accordion1 .card-header button.collapsed:before,
#accordion2 .card-header button.collapsed:before,
#accordion3 .card-header button.collapsed:before,
#accordion4 .card-header button.collapsed:before,
#accordion5 .card-header button.collapsed:before,
#accordion6 .card-header button.collapsed:before,
#accordion7 .card-header button.collapsed:before,
#accordion8 .card-header button.collapsed:before {
  content: "\f105";
}

.progress-bar {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #008cff;
  transition: width 0.6s ease;
}

/* Background Colors */
.bg-primary {
  background-color: #008cff !important;
}
.bg-success {
  background-color: #15ca20 !important;
}
.bg-info {
  background-color: #0dceec !important;
}
.bg-secondary {
  background-color: #75808a !important;
}
.bg-danger {
  background-color: #fd3550 !important;
}
.bg-dark {
  background-color: #223035 !important;
}
.bg-warning {
  background-color: #ff9700 !important;
}
.bg-light {
  background-color: #e9eaea !important;
}
.bg-contrast {
  background: rgba(255, 255, 255, 0.3) !important;
}
.gradient-bloody {
  background: #f54ea2;
  background: -webkit-linear-gradient(45deg, #f54ea2, #ff7676) !important;
  background: linear-gradient(45deg, #f54ea2, #ff7676) !important;
}

.gradient-scooter {
  background: #17ead9;
  background: -webkit-linear-gradient(45deg, #17ead9, #6078ea) !important;
  background: linear-gradient(45deg, #17ead9, #6078ea) !important;
}

.gradient-blooker {
  background: #ffdf40;
  background: -webkit-linear-gradient(45deg, #ffdf40, #ff8359) !important;
  background: linear-gradient(45deg, #ffdf40, #ff8359) !important;
}

.gradient-quepal {
  background: #42e695;
  background: -webkit-linear-gradient(45deg, #42e695, #3bb2b8) !important;
  background: linear-gradient(45deg, #42e695, #3bb2b8) !important;
}

.gradient-purpink {
  background: #7f00ff;
  background: -webkit-linear-gradient(45deg, #7f00ff, #e100ff) !important;
  background: linear-gradient(45deg, #7f00ff, #e100ff) !important;
}

.gradient-ibiza {
  background: #ee0979;
  background: -webkit-linear-gradient(45deg, #ee0979, #ff6a00) !important;
  background: linear-gradient(45deg, #ee0979, #ff6a00) !important;
}

.gradient-dusk {
  background: #2c3e50;
  background: -webkit-linear-gradient(45deg, #2c3e50, #fd746c) !important;
  background: linear-gradient(45deg, #2c3e50, #fd746c) !important;
}

.gradient-meridian {
  background: #283c86;
  background: -webkit-linear-gradient(45deg, #283c86, #45a247) !important;
  background: linear-gradient(45deg, #283c86, #45a247) !important;
}

.gradient-violet {
  background: #4776e6;
  background: -webkit-linear-gradient(45deg, #4776e6, #8e54e9) !important;
  background: linear-gradient(45deg, #4776e6, #8e54e9) !important;
}

.gradient-titanium {
  background: #283048;
  background: -webkit-linear-gradient(45deg, #283048, #859398) !important;
  background: linear-gradient(45deg, #283048, #859398) !important;
}

.gradient-shifter {
  background: #bc4e9c;
  background: -webkit-linear-gradient(45deg, #bc4e9c, #f80759) !important;
  background: linear-gradient(45deg, #bc4e9c, #f80759) !important;
}

.gradient-army {
  background: #414d0b;
  background: -webkit-linear-gradient(45deg, #414d0b, #727a17) !important;
  background: linear-gradient(45deg, #414d0b, #727a17) !important;
}

.gradient-orange {
  background: #fc4a1a;
  background: -webkit-linear-gradient(45deg, #fc4a1a, #f7b733) !important;
  background: linear-gradient(45deg, #fc4a1a, #f7b733) !important;
}

.gradient-yoda {
  background: #ff0099;
  background: -webkit-linear-gradient(45deg, #ff0099, #493240) !important;
  background: linear-gradient(45deg, #ff0099, #493240) !important;
}

.gradient-royal {
  background: #141e30;
  background: -webkit-linear-gradient(45deg, #141e30, #2c527d) !important;
  background: linear-gradient(45deg, #141e30, #2c527d) !important;
}

.gradient-knight {
  background: #ba8b02;
  background: -webkit-linear-gradient(45deg, #ba8b02, #181818) !important;
  background: linear-gradient(45deg, #ba8b02, #181818) !important;
}

.gradient-forest {
  background: #000428;
  background: -webkit-linear-gradient(45deg, #000428, #004e92) !important;
  background: linear-gradient(45deg, #000428, #004e92) !important;
}

.gradient-redmist {
  background: #000000;
  background: -webkit-linear-gradient(45deg, #000000, #e74c3c) !important;
  background: linear-gradient(45deg, #000000, #e74c3c) !important;
}

.gradient-steelgray {
  background: #1f1c2c;
  background: -webkit-linear-gradient(45deg, #1f1c2c, #928dab) !important;
  background: linear-gradient(45deg, #1f1c2c, #928dab) !important;
}

.gradient-influenza {
  background: #c04848;
  background: -webkit-linear-gradient(45deg, #c04848, #480048) !important;
  background: linear-gradient(45deg, #c04848, #480048) !important;
}

.gradient-flame {
  background: #ff9a9e;
  background: -webkit-linear-gradient(45deg, #ff9a9e, #fad0c4) !important;
  background: linear-gradient(45deg, #ff9a9e, #fad0c4) !important;
}

.gradient-cherry {
  background: #fbd3e9;
  background: -webkit-linear-gradient(45deg, #fbd3e9, #bb377d) !important;
  background: linear-gradient(45deg, #fbd3e9, #bb377d) !important;
}

.gradient-winter {
  background: #a1c4fd;
  background: -webkit-linear-gradient(45deg, #a1c4fd, #c2e9fb) !important;
  background: linear-gradient(45deg, #a1c4fd, #c2e9fb) !important;
}

.gradient-rain {
  background: #cfd9df;
  background: -webkit-linear-gradient(45deg, #cfd9df, #e2ebf0) !important;
  background: linear-gradient(45deg, #cfd9df, #e2ebf0) !important;
}

.gradient-telegram {
  background: #1c92d2;
  background: -webkit-linear-gradient(45deg, #1c92d2, #f2fcfe) !important;
  background: linear-gradient(45deg, #1c92d2, #f2fcfe) !important;
}

.gradient-horizon {
  background: #fceabb;
  background: -webkit-linear-gradient(45deg, #fceabb, #f8b500) !important;
  background: linear-gradient(45deg, #fceabb, #f8b500) !important;
}

.gradient-peach {
  background: #ffecd2;
  background: -webkit-linear-gradient(45deg, #ffecd2, #fcb69f) !important;
  background: linear-gradient(45deg, #ffecd2, #fcb69f) !important;
}

.gradient-meadow {
  background: #215f00;
  background: -webkit-linear-gradient(45deg, #215f00, #e4e4d9) !important;
  background: linear-gradient(45deg, #215f00, #e4e4d9) !important;
}

/* Borders */
.border-primary {
  border-color: #008cff !important;
}
.border-success {
  border-color: #15ca20 !important;
}
.border-info {
  border-color: #0dceec !important;
}
.border-secondary {
  border-color: #75808a !important;
}
.border-danger {
  border-color: #fd3550 !important;
}
.border-dark {
  border-color: #223035 !important;
}
.border-warning {
  border-color: #ff9700 !important;
}
.border-light {
  border-color: #e9eaea !important;
}

/* Text Colors */
.text-primary {
  color: #008cff !important;
}
.text-success {
  color: #15ca20 !important;
}
.text-info {
  color: #0dceec !important;
}
.text-secondary {
  color: #75808a !important;
}
.text-danger {
  color: #fd3550 !important;
}
.text-dark {
  color: #223035 !important;
}
.text-warning {
  color: #ff9700 !important;
}
.text-light {
  color: #e9eaea !important;
}

/* Shadows */
.shadow-primary {
  box-shadow: 0 7px 30px rgba(2, 140, 253, 0.7) !important;
}
.shadow-secondary {
  box-shadow: 0 7px 30px rgba(117, 129, 137, 0.83) !important;
}
.shadow-success {
  box-shadow: 0 7px 30px rgba(21, 201, 33, 0.74) !important;
}
.shadow-danger {
  box-shadow: 0 7px 30px rgba(253, 53, 80, 0.7) !important;
}
.shadow-warning {
  box-shadow: 0 7px 30px rgba(254, 151, 1, 0.65) !important;
}
.shadow-info {
  box-shadow: 0 7px 30px rgba(13, 206, 236, 0.73) !important;
}
.shadow-light {
  box-shadow: 0 7px 30px rgba(186, 193, 193, 0.83) !important;
}
.shadow-dark {
  box-shadow: 0 7px 30px rgba(34, 48, 53, 0.81) !important;
}

/* CheckBoxes & Radios */
.demo-checkbox label,
.demo-radio-button label {
  min-width: 200px;
  margin-bottom: 20px;
}
.demo-swtich .demo-switch-title,
.demo-swtich .switch {
  width: 150px;
  margin-bottom: 10px;
  display: inline-block;
}

[type="checkbox"]:checked.chk-col-primary + label:before {
  border-right: 2px solid #008cff;
  border-bottom: 2px solid #008cff;
}

[type="checkbox"]:checked.chk-col-success + label:before {
  border-right: 2px solid #15ca20;
  border-bottom: 2px solid #15ca20;
}

[type="checkbox"]:checked.chk-col-info + label:before {
  border-right: 2px solid #0dceec;
  border-bottom: 2px solid #0dceec;
}

[type="checkbox"]:checked.chk-col-warning + label:before {
  border-right: 2px solid #ff9700;
  border-bottom: 2px solid #ff9700;
}

[type="checkbox"]:checked.chk-col-danger + label:before {
  border-right: 2px solid #fd3550;
  border-bottom: 2px solid #fd3550;
}

[type="checkbox"]:checked.chk-col-dark + label:before {
  border-right: 2px solid #223035;
  border-bottom: 2px solid #223035;
}

[type="checkbox"]:checked.chk-col-secondary + label:before {
  border-right: 2px solid #75808a;
  border-bottom: 2px solid #75808a;
}

[type="checkbox"].filled-in:checked + label:before {
  border-right: 2px solid #fff !important;
  border-bottom: 2px solid #fff !important;
}

[type="checkbox"].filled-in:checked.chk-col-primary + label:after {
  border: 2px solid #008cff;
  background-color: #008cff;
}
[type="checkbox"].filled-in:checked.chk-col-success + label:after {
  border: 2px solid #15ca20;
  background-color: #15ca20;
}
[type="checkbox"].filled-in:checked.chk-col-info + label:after {
  border: 2px solid #0dceec;
  background-color: #0dceec;
}
[type="checkbox"].filled-in:checked.chk-col-warning + label:after {
  border: 2px solid #ff9700;
  background-color: #ff9700;
}
[type="checkbox"].filled-in:checked.chk-col-danger + label:after {
  border: 2px solid #fd3550;
  background-color: #fd3550;
}
[type="checkbox"].filled-in:checked.chk-col-dark + label:after {
  border: 2px solid #223035;
  background-color: #223035;
}
[type="checkbox"].filled-in:checked.chk-col-secondary + label:after {
  border: 2px solid #75808a;
  background-color: #75808a;
}

[type="radio"].radio-col-primary:checked + label:after {
  background-color: #008cff;
  border-color: #008cff;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].radio-col-success:checked + label:after {
  background-color: #15ca20;
  border-color: #15ca20;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].radio-col-info:checked + label:after {
  background-color: #0dceec;
  border-color: #0dceec;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].radio-col-warning:checked + label:after {
  background-color: #ff9700;
  border-color: #ff9700;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}

[type="radio"].radio-col-danger:checked + label:after {
  background-color: #fd3550;
  border-color: #fd3550;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].radio-col-dark:checked + label:after {
  background-color: #223035;
  border-color: #223035;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].radio-col-secondary:checked + label:after {
  background-color: #75808a;
  border-color: #75808a;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-primary:checked + label:before {
  border: 2px solid #008cff;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-primary:checked + label:after {
  background-color: #008cff;
  border: 2px solid #008cff;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}

[type="radio"].with-gap.radio-col-success:checked + label:before {
  border: 2px solid #15ca20;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-success:checked + label:after {
  background-color: #15ca20;
  border: 2px solid #15ca20;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-info:checked + label:before {
  border: 2px solid #0dceec;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-info:checked + label:after {
  background-color: #0dceec;
  border: 2px solid #0dceec;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-warning:checked + label:before {
  border: 2px solid #ff9700;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-warning:checked + label:after {
  background-color: #ff9700;
  border: 2px solid #ff9700;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-danger:checked + label:before {
  border: 2px solid #fd3550;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-danger:checked + label:after {
  background-color: #fd3550;
  border: 2px solid #fd3550;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-dark:checked + label:before {
  border: 2px solid #223035;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-dark:checked + label:after {
  background-color: #223035;
  border: 2px solid #223035;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-secondary:checked + label:before {
  border: 2px solid #75808a;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap.radio-col-secondary:checked + label:after {
  background-color: #75808a;
  border: 2px solid #75808a;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
[type="checkbox"] + label {
  font-weight: 400;
  position: relative;
  padding-left: 35px !important;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
}
[type="checkbox"] + label:before,
[type="checkbox"]:not(.filled-in) + label:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #5a5a5a;
  border-radius: 1px;
  margin-top: 2px;
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
[type="checkbox"]:not(.filled-in) + label:after {
  border: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
[type="checkbox"]:not(:checked):disabled + label:before {
  border: none;
  background-color: rgba(0, 0, 0, 0.26);
}
[type="checkbox"].tabbed:focus + label:after {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  border: 0;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}
[type="checkbox"]:checked + label:before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #45aef1;
  border-bottom: 2px solid #45aef1;
  -webkit-transform: rotate(40deg);
  -ms-transform: rotate(40deg);
  transform: rotate(40deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
[type="checkbox"]:checked:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  border-bottom: 2px solid rgba(0, 0, 0, 0.26);
}
[type="checkbox"]:indeterminate + label:before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: 2px solid #26a69a;
  border-bottom: none;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
[type="checkbox"]:indeterminate:disabled + label:before {
  border-right: 2px solid rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
[type="checkbox"].filled-in + label:after {
  border-radius: 2px;
}
[type="checkbox"].filled-in + label:after,
[type="checkbox"].filled-in + label:before {
  content: "";
  left: 0;
  position: absolute;
  -webkit-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
    height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  -o-transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
    height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  transition: border 0.25s, background-color 0.25s, width 0.2s 0.1s,
    height 0.2s 0.1s, top 0.2s 0.1s, left 0.2s 0.1s;
  z-index: 1;
}
[type="checkbox"].filled-in:not(:checked) + label:before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  -webkit-transform: rotateZ(37deg);
  -ms-transform: rotate(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 20% 40%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
[type="checkbox"].filled-in:not(:checked) + label:after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #5a5a5a;
  top: 0;
  z-index: 0;
}
[type="checkbox"].filled-in:checked + label:before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  -webkit-transform: rotateZ(37deg);
  -ms-transform: rotate(37deg);
  transform: rotateZ(37deg);
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
[type="checkbox"].filled-in:checked + label:after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #45aef1;
  background-color: #45aef1;
  z-index: 0;
}
[type="checkbox"].filled-in.tabbed:focus + label:after {
  border-radius: 2px;
  border-color: #5a5a5a;
  background-color: rgba(0, 0, 0, 0.1);
}
[type="checkbox"].filled-in.tabbed:checked:focus + label:after {
  border-radius: 2px;
  background-color: #45aef1;
  border-color: #45aef1;
}
[type="checkbox"].filled-in:disabled:not(:checked) + label:before {
  background-color: transparent;
  border: 2px solid transparent;
}
[type="checkbox"].filled-in:disabled:not(:checked) + label:after {
  border-color: transparent;
  background-color: #bdbdbd;
}
[type="checkbox"].filled-in:disabled:checked + label:before {
  background-color: transparent;
}
[type="checkbox"].filled-in:disabled:checked + label:after {
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
  opacity: 0;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label {
  font-weight: 400;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  -webkit-transition: 0.28s ease;
  -o-transition: 0.28s ease;
  transition: 0.28s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
[type="radio"] + label:after,
[type="radio"] + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin: 4px;
  width: 16px;
  height: 16px;
  z-index: 0;
  -webkit-transition: 0.28s ease;
  -o-transition: 0.28s ease;
  transition: 0.28s ease;
}
[type="radio"].with-gap:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"]:checked + label:after,
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:after,
[type="radio"]:not(:checked) + label:before {
  border-radius: 50%;
}
[type="radio"]:not(:checked) + label:after,
[type="radio"]:not(:checked) + label:before {
  border: 2px solid #5a5a5a;
}
[type="radio"]:not(:checked) + label:after {
  z-index: -1;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:before {
  border: 2px solid transparent;
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"]:checked + label:after {
  border: 2px solid #389af0;
}
[type="radio"].with-gap:checked + label:after,
[type="radio"]:checked + label:after {
  background-color: #389af0;
  z-index: 0;
}
[type="radio"]:checked + label:after {
  -webkit-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}
[type="radio"].with-gap:checked + label:after {
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
}
[type="radio"].tabbed:focus + label:before {
  -webkit-box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap:disabled:checked + label:before {
  border: 2px solid rgba(0, 0, 0, 0.26);
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"].with-gap:disabled:checked + label:after {
  border: none;
  background-color: rgba(0, 0, 0, 0.26);
}
[type="radio"]:disabled:checked + label:before,
[type="radio"]:disabled:not(:checked) + label:before {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.26);
  -webkit-animation: ripple 0.2s linear forwards;
  animation: ripple 0.2s linear forwards;
}
[type="radio"]:disabled + label {
  color: rgba(0, 0, 0, 0.26);
}
[type="radio"]:disabled:not(:checked) + label:before {
  border-color: rgba(0, 0, 0, 0.26);
}
[type="radio"]:disabled:checked + label:after {
  background-color: rgba(0, 0, 0, 0.26);
  border-color: #bdbdbd;
}
input[type="color"] {
  height: 42px;
}

.input-group-addon [type="checkbox"]:checked,
.input-group-addon [type="checkbox"]:not(:checked),
.input-group-addon [type="radio"]:checked,
.input-group-addon [type="radio"]:not(:checked) {
  position: initial;
  opacity: 1;
  margin-top: 4px;
}

.navbar-sidenav-tooltip.show {
  display: none;
}

.card-body-icon {
  position: absolute;
  z-index: 0;
  top: -25px;
  right: -25px;
  font-size: 5rem;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}

.card-authentication1 {
  max-width: 24rem;
}

.card-authentication2 {
  max-width: 50rem;
}

.bg-signup2 {
  /* background: url(../images/gallery/authentication-img1.jpg) no-repeat center; */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.bg-signin2 {
  /* background-image: url(../images/gallery/authentication-img2.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.bg-reset-password2 {
  /* background-image: url(../images/gallery/authentication-img3.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.footer {
  bottom: 0px;
  color: #272727;
  text-align: center;
  padding: 12px 30px;
  position: absolute;
  right: 0;
  left: 240px;
  background-color: #f9f9f9;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
#wrapper.toggled .footer {
  position: absolute;
  left: 0px;
}
.back-to-top {
  display: none;
  width: 40px;
  height: 40px;
  text-align: center;
  color: white;
  position: fixed;
  border-radius: 50%;
  bottom: 20px;
  right: 12px;
  background-color: #008cff;
  z-index: 10000;
  box-shadow: 1px 1px 40px 0px rgba(73, 220, 251, 0.32);
}
.back-to-top .fa-angle-double-up {
  margin-top: 20%;
  font-size: 20px;
}
.back-to-top:hover {
  color: white;
  background-color: #2f353f;
  transition: all 0.5s;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.waves-effect .waves-ripple {
  position: absolute;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.7s ease-out;
  -moz-transition: all 0.7s ease-out;
  -o-transition: all 0.7s ease-out;
  -ms-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  -o-transition-property: -o-transform, opacity;
  transition-property: transform, opacity;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  pointer-events: none;
}
.waves-effect.waves-light .waves-ripple {
  background-color: rgba(255, 255, 255, 0.45);
}
.waves-effect.waves-red .waves-ripple {
  background-color: rgba(244, 67, 54, 0.7);
}
.waves-effect.waves-yellow .waves-ripple {
  background-color: rgba(255, 235, 59, 0.7);
}
.waves-effect.waves-orange .waves-ripple {
  background-color: rgba(255, 152, 0, 0.7);
}
.waves-effect.waves-purple .waves-ripple {
  background-color: rgba(156, 39, 176, 0.7);
}
.waves-effect.waves-green .waves-ripple {
  background-color: rgba(76, 175, 80, 0.7);
}
.waves-effect.waves-teal .waves-ripple {
  background-color: rgba(0, 150, 136, 0.7);
}
.waves-notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  -ms-transition: none !important;
  transition: none !important;
}
.waves-circle {
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-mask-image: -webkit-radial-gradient(circle, white 100%, black 100%);
  text-align: center;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  border-radius: 50%;
  -webkit-mask-image: none;
}
.waves-input-wrapper {
  border-radius: 0.2em;
  vertical-align: bottom;
}
.waves-input-wrapper .waves-button-input {
  position: relative;
  top: 0;
  left: 0;
  z-index: 1;
}
.waves-block {
  display: block;
}

/* Pricing Tables */
.pricing-table .list-group {
  box-shadow: none;
  padding-left: 1rem;
  padding-right: 1rem;
}
.price-title {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 600;
}
.price {
  font-size: 3.5em;
  line-height: 1.8em;
  font-weight: 600;
  margin-bottom: 0;
}
.currency {
  font-size: 35px;
}
.pricing-table .card .card-body .list-group .list-group-item:first-child {
  border-top: 0px;
}
.pricing-table .card .card-body .list-group .list-group-item:last-child {
  border-bottom: 0px;
}
.pricing-table .card .card-body .list-group .list-group-item {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.05);
}
.pricing-table.pricing-table-color
  .card
  .card-body
  .list-group
  .list-group-item {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}

.error-pages {
  margin-top: 230px;
}

.error-title {
  font-size: 140px;
  line-height: 140px;
  text-transform: uppercase;
  font-weight: bold;
}

.error-sub-title {
  font-size: 30px;
  line-height: 30px;
  text-transform: uppercase;
}

.bg-error {
  /* background-image: url(../images/texture.jpg); */
}

.bg-coming-soon {
  /* background-image: url(../images/coming-soon.png); */
}

.coming-soon {
  margin-top: 230px;
}

.coming-soon-title {
  font-size: 35px;
  line-height: 35px;
  text-transform: uppercase;
}

/* Responsive */
@media only screen and (max-width: 1366px) {
  .error-pages {
    margin-top: 110px;
  }

  .coming-soon {
    margin-top: 110px;
  }
}

@media only screen and (max-width: 1199px) {
  .row.row-group > div {
    border-right: 0;
    border-bottom: 1px solid rgba(234, 234, 234, 0.9);
  }

  .row.row-group > div:last-child {
    border-right: none;
    border-bottom: 0;
  }
}

@media only screen and (max-width: 1024px) {
  .search-bar {
    margin-left: 10px;
    position: relative;
  }

  .search-bar input {
    width: 100%;
  }

  .nav-tabs .nav-link {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 767px) {
  #sidebar-wrapper {
	top: 60px;
    left: -240px;
}

  .toggle-menu i {
    line-height: 60px;
    margin-left: 0px;
    font-size: 15px;
    color: white;
    cursor: pointer;
  }

  .card {
    margin-bottom: 20px;
  }

  .card-deck {
    margin-bottom: 20px;
  }

  .card-group {
    margin-bottom: 20px;
  }

  .content-wrapper {
    margin-left: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .footer {
    position: absolute;
    left: 0px;
  }

  /* #wrapper.toggled #sidebar-wrapper {
    position: fixed;
    top: 60px;
    left: 0px;
    box-shadow: 2px 10px 30px 0 rgba(0, 0, 0, 0.11),
      0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
  } */

  #wrapper.toggled .menu-icon {
    margin-left: 0px;
  }

  #wrapper.toggled .content-wrapper {
    margin-left: 0px;
  }

  #wrapper.toggled .footer {
    position: absolute;
    left: 0px;
  }

  .hidden-xs {
    display: none !important;
  }
}

@media only screen and (max-width: 575px) {
  .bg-signup2 {
    height: 35rem;
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .bg-signin2 {
    height: 25rem;
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .bg-reset-password2 {
    height: 20rem;
    border-radius: 0;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
}

@media only screen and (max-width: 480px) {
  .search-bar {
    margin-left: 5px;
    position: relative;
  }

  .search-bar input {
    width: 100%;
  }

  .dropdown-lg {
    display: none;
  }

  .nav-item.language {
    display: none;
  }

  .right-nav-link a.nav-link {
    padding-right: 0rem !important;
  }

  .topbar-nav .navbar {
    padding: 0px 10px 0 5px;
  }
}
