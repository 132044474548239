

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 0.5s;
}
.cardUserAddress .avatar {
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  border: 2px solid #fff;
}
.cardUserAddress:nth-of-type(1n) .avatar {
  /* background: url('../../assets/profiles/profile.jpeg'), no-repeat; */
  background-size: cover;
}
.cardUserAddress:nth-of-type(2n) .avatar {
  /* background: url('../../assets/profiles/profile.jpg'), no-repeat; */
  background-size: cover;
}
.cardUserAddress:nth-of-type(3n) .avatar {
  /* background: url('../../assets/profiles/profile1.jpg'), no-repeat; */
  background-size: cover;
}
.cardUserAddress:nth-of-type(4n) .avatar {
  /* background: url('../../assets/profiles/profile2.jpeg'), no-repeat; */
  background-size: cover;
}
.cardUserAddress .id {
  text-transform: uppercase;
}
.cardUserAddress .name {
  font-size:27px;
  color: #000;
}
.cardUserAddress .cpf,
.cell {
  color: #000;
  font-size: 16px;
}
.cardUserAddress .dropAddress {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.01);
}
.cardUserAddress .dropAddress i {
  color: mediumseagreen;
  font-size: 20px;
}
.cardUserAddress .qntdAddress {
  font-size: 16px;
  color: #000;
}
tr{
  text-align: center;
  
}

#table_wrapper {
  width: 100%;
}
.datesTables table thead tr {
  color: #000;
}
.datesTables table tbody tr td {
  color: #000;
  font-weight: 100;
}
.datesTables table tbody tr:nth-of-type(2n) {
  background: rgba(255, 255, 255, 0.03);
}

.large-card {
  width: 800px !important;
}
.leftAvatar {
  transition: 0.5s;
  left: 20px !important;
}
.leftId {
  transition: 0.5s;
  left: 180px !important;
}
.leftName {
  display: inline !important;
  position: relative;
  transition: 0.5s;
  text-align: left !important;
  left: 10px !important;
}
.leftCName {
  transition: 0.5s;
  left: 100px !important;
}
.tableOn {
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
}
.tableOff {
  display: block;
  position: absolute;
  right: 10px;
  top: 10px;
}

/* Table Styles */
.tdAvatar{
  width: 20px !important
}